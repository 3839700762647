.b-users-page {
    margin: 11px 0 0;

    .subheader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .breadcrumbs-holder {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .breadcrumbs-item {
                & + .breadcrumbs-item {
                    position: relative;
                    margin-left: 30px;

                    &:before {
                        position: absolute;
                        top: 50%;
                        transform: translate(50%, -50%);
                        right: calc(100% + 12px);
                        color: black;
                        content: '/\00a0';
                        font-size: 1em;
                        line-height: 1.172;
                        font-weight: $f-medium;
                        color: rgba(0, 0, 0, 0.38);
                    }
                }

                &-link {
                    font-size: 1em;
                    line-height: 1.172;
                    font-weight: $f-medium;
                    color: rgba(0, 0, 0, 0.2);

                    &.active {
                        color: $darkBlue;
                    }
                }
            }
        }

        .controls-holder {
            display: flex;
            justify-content: flex-end;
            align-items: stretch;

            .g-button {
              min-height: 52px;

              &.filter {
                    padding: 15.5px 33px 15.5px 19px;
                    border-radius: 0px 4px 4px 0px;
                    text-transform: none;
                }

                & + .g-button {
                    margin-left: 10px;
                }
            }
        }
    }

    .users-wrapper {
      height: calc(100vh - 250px);
      padding-top: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      margin: 17px -8px 0;
      overflow-y: auto;
      overflow-x: hidden;

      /* 1 - скроллбар */
      &::-webkit-scrollbar {
        width: 5px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
      }

      /* 2 - кнопка */
      &::-webkit-scrollbar-button {
        display: none;
      }

      /* 3 - трек */
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.04);

      }

      /* 4 - видимая часть трека */
      &::-webkit-scrollbar-track-piece {
        background: rgba(0, 0, 0, 0.04);

      }
      /* 5 - ползунок */
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.38);
      }

      //For Mozilla FireFox
      scrollbar-color: rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.04);

      .users-holder {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;


        .users-item {
          width: 50%;
          padding: 8px;

          &-inner {
            padding: 16px 23px 16px 16px;
            background-color: $white;
            border-radius: 8px;
            cursor: pointer;

            .item-header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &-left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 8px;
                color: rgba(0, 0, 0, 0.2);

                .number {
                  font-size: 1em;
                  line-height: 1.17;
                  font-weight: $f-medium;
                  margin-right: 9px;
                }

                .role {
                  font-size: 0.8em;
                  line-height: 1.17;
                  font-weight: $f-medium;
                }
              }

              &-right {
                margin-left: 15px;
                display: flex;
                align-items: stretch;

                .edit-user {
                  display: block;

                  &:hover {
                    opacity: 0.6;
                  }
                }

                .remove-user {
                  margin-left: 10px;

                  &:hover {
                    opacity: 0.6;
                  }
                }

                .icon {
                  height: 100%;
                  display: block;
                }
              }
            }

            .name {
              margin-top: 16px;
              font-size: 0.8em;
              line-height: 1.17;
              font-weight: $f-medium;
              color: rgba(0, 0, 0, 0.6);
            }

            .contacts-holder {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 18px;

              .contacts-item {
                display: flex;
                justify-content: start;
                align-items: center;
                font-size: 0.7em;
                line-height: 1.21;
                color: rgba(0, 0, 0, 0.6);
                white-space: nowrap;

                & + .contacts-item {
                  margin-left: 32px;
                }

                .icon {
                  display: block;
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }
    }
}
