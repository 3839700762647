@import 'variables';
@import 'reset';
@import 'global';
@import 'styles-reset';

// Blocks
@import 'blocks/login';
@import 'blocks/form';
@import 'blocks/header';
@import 'blocks/modal_info';
@import 'blocks/modal_edit';
@import 'blocks/modal_filter';
@import 'blocks/reference-book';
@import 'blocks/users_page';
@import 'blocks/map';
@import 'blocks/pagination';
@import 'blocks/alerts';
@import 'blocks/custom_select';
@import 'blocks/dateRangePicker';
@import 'blocks/table';
