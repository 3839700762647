.table-holder {
  border-top: 1px solid rgba(0,0,0,.12);
  position: relative;
}

td {
  color: #000000;

  &:first-child {
    padding-left: 10px;
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }
}

th {
  color: rgba(0, 0, 0, 0.54);
}

.td-item-wrapper {

  &+.td-item-wrapper {
    border-top: 1px solid #D5D5D5;
    padding-top: 8px;
    margin-top: 8px;
  }
}

.th-item-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  .sorting-icon {
    margin-left: 8px;
  }
}