.b-alerts {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1400;

  .alerts-holder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    word-break: break-all;

    .alert {
      position: relative;
      padding: 18px 23px 16px 48px;
      max-width: 416px;
      width: 400px;
      border: 1px solid;
      border-radius: 4px;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

      &+.alert {
        margin-top: 8px;
      }

      &-title {
        position: relative;
        font-size: 14px;
        line-height: 1.43;
        font-weight: $f-medium;
        color: rgba(0, 0, 0, 0.87);

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          right: calc(100% + 10px);
          top: 50%;
          transform: translateY(-50%);
          background-size: contain;
        }
      }

      &-message {
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.43;
        font-weight: $f-regular;
        color: rgba(0, 0, 0, 0.87);
      }

      &-close {
        position: absolute;
        top: 13px;
        right: 15px;
      }

      &.done {
        border-color: $lightGreen;
        background-color: $lightGreenSecond;

        .alert-title {
          &:before {
            background: url('../../img/icon_alert_done.svg') no-repeat center;
          }
        }
      }

      &.attention {
        border-color: $lightYellow;
        background-color: $lightYellowSecond;

        .alert-title {
          &:before {
            background: url('../../img/icon_alert_attention.svg') no-repeat center;
          }
        }
      }

      &.error {
        border-color: $lightRed;
        background-color: $lightRedSecond;

        .alert-title {
          &:before {
            background: url('../../img/icon_alert_error.svg') no-repeat center;
          }
        }
      }
    }
  }
}