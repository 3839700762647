.daterangepicker {
    td {
        &.active {
            background-color: #2e3e67;

            &:hover {
                background-color: #2e3e67;
            }
        }
    }

    &.single {
        .drp-buttons {
            button {
                padding: 5px 10px;
                height: auto;
                text-transform: none;
                cursor: pointer;
            }
        }
    }

    .drp-buttons {
        border: none;
        margin-top: 45px;
        display: flex !important;
        justify-content: space-between;

        .btn {
            font-weight: normal;
            font-size: 16px;
            line-height: 1;
            height: 40px;
            border: none;
            padding: 12px 30px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            background-color: #2e3e67;
            color: $white;
            margin: 0;
            border: none;
            appearance: none;

            & + .btn {
                margin-left: 15px;
            }
        }

        .btn-apply {
        }
    }

    .drp-selected {
        display: none;
    }

    .calendar-table {
        border: none;
        background-color: transparent;
    }
}
