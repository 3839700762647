// colors
$white: #ffffff;
$black: #000000;
$lightGray: #ececec;
$darkBlue: #2e3e67;
$lightRed: #ff5252;
$lightRed: #ff5252;
$lightGreen: #4caf50;
$lightYellow: #fb8c00;
$lightRedSecond: #ffe4e4;
$lightGreenSecond: #e0f3e0;
$lightYellowSecond: #ffebd2;

// fonts
$f-light: 300;
$f-regular: 400;
$f-medium: 500;
$f-bold: 700;
$f-extra-bold: 800;
$f-black: 900;

$font-default: 20px;

//transitions
$all-transition: all 0.2s ease-in-out;
$slow-all-transition: all 0.6s ease-in-out;

//screen sizes
$extraSmallMobile: 320px;
$smallMobile: 414px;
$mobile: 767px;
$tablet: 991px;
$desktop: 1140px;
