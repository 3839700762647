.b-modal-filter {
    //position: fixed;
    // top: 0;
    // left: 0;
    width: 383px;
    height: 100%;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.5);
    // display: none;

    .modal-content-wrapper {
        position: fixed;
        display: flex;
        flex-direction: column;
        right: 0;
        top: 0;
        width: 383px;
        background-color: $white;
        height: calc(100% - 77px);
        overflow-y: auto;

        /* 1 - скроллбар */
        &::-webkit-scrollbar {
          width: 5px;
          //height: 8px;
          background-color: rgba(0, 0, 0, 0.04);
          border-radius: 4px;
        }

        /* 2 - кнопка */
        &::-webkit-scrollbar-button {
          display: none;
        }

        /* 3 - трек */
        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.04);

        }

        /* 4 - видимая часть трека */
        &::-webkit-scrollbar-track-piece {
          background: rgba(0, 0, 0, 0.04);

        }
        /* 5 - ползунок */
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.38);
        }

        //For Mozilla FireFox
        scrollbar-color: rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.04);

        .modal-header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 33px 16px 0;
            background-color: $white;

            .title {
                font-size: 1em;
                line-height: 1.17;
                font-weight: $f-medium;
                color: rgba(0, 0, 0, 0.74);
            }

            .subtitle {
                margin-top: 32px;
                font-size: 0.65em;
                line-height: 1.23;
                font-weight: $f-medium;
                color: rgba(0, 0, 0, 0.38);
            }
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-bottom: 17px;

            &.without-tabs {
                padding: 0px 16px;

                .info-title {
                    padding-bottom: 24px;
                    border-bottom: 1px solid #d5d5d5;
                }

                .b-form {
                    margin-top: 0;
                    margin-top: 0;

                    .row-title {
                      margin-bottom: 16px;

                      &.with-top-border {
                        padding-top: 14px;
                        margin-top: 16px;
                      }
                    }
                }
            }

            .tabs-header {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;

                &-link {
                    position: relative;
                    font-size: 0.8em;
                    line-height: 1.4;
                    font-weight: $f-medium;
                    color: rgba(0, 0, 0, 0.54);
                    padding: 0 16px 0 16px;
                    transition: all 0.3s ease-in-out;

                    &.active {
                        color: $darkBlue;
                    }
                }
            }

            .tabs-content {
                flex-grow: 1;

                &-item {
                    margin-top: 24px;
                    padding-top: 24px;
                    border-top: 1px solid #d5d5d5;
                    height: calc(100% - 24px);

                    &.faded {
                        animation-name: faded;
                        animation-duration: 0.5s;
                    }
                }
            }

            .map-holder {
                width: 100%;
                border-radius: 12px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.19);
                margin-bottom: 25px;

                .image {
                    width: 100%;
                    display: block;
                }
            }

            .b-form {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .controls-wrapper {
                    .controls-holder {
                        position: fixed;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 23px 16px 19px;
                        background-color: $white;
                        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
                        //margin: 0 -24px 0;
                        width: 383px;
                        z-index: 10;

                        .g-button {
                            padding: 9.5px 10px;
                            line-height: 1.21;
                            width: 100%;

                            &.with-border {
                                padding: 8.5px 9px;
                            }

                            &.white {
                                background-color: white;
                                color: #2e3e67;
                                border: 1px solid #2e3e67;

                                &:disabled {
                                  color: rgba(0, 0, 0, 0.38);
                                  border: 1px solid rgba(0, 0, 0, 0.38);
                                }
                            }

                            & + .g-button {
                                margin-left: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes faded {
    from {
        opacity: 0.1;
    }

    to {
        opacity: 1;
    }
}
