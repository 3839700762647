//START jcf-select customization
.jcf-select {
  width: 100%;
  height: 100%;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  min-width: auto !important;
  padding: 8px 15px !important;

  &.jcf-disabled {
    background: rgba(0,0,0,.04) !important;


    .jcf-select-text {
      color: rgba(0,0,0,.2);
    }
  }


  &.jcf-focus {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  &.jcf-drop-active {
    border-radius: 2px 2px 0 0!important;
  }


  .jcf-select-text {
    margin: 0;
    font-size: 14px;
    line-height: 1.43em;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;

    .jcf-option-color {
      &.jcf-option-lightGray {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .jcf-select-opener {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: none;
    width: auto;
    height: auto;
    right: 22px;
    cursor: pointer;

    &::before {
      display: block;
      content: '';
      width: 12px;
      height: 7.42px;
      margin: 0;
      padding: 0;
      background-size: cover;
      background-image: url('../../img/icon_select_arrow.svg');
      transition: all 0.3s ease-in-out;
    }
  }
  &.jcf-compact-multiple {
    .jcf-select-opener {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: none;
      width: auto;
      height: auto;
      right: 22px;
      cursor: pointer;

      &::before {
        display: block;
        content: '';
        width: 12px;
        height: 7.42px;
        margin: 0;
        padding: 0;
        background-size: cover;
        background-image: url('../../img/icon_select_arrow.svg');
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &.jcf-drop-active {
    .jcf-select-opener {

      &::before {
        transform: rotate(180deg);
      }
    }
  }

  .jcf-select-drop {
    width: auto !important;
    min-width: 100%;
    top: calc(100%+ 1px);

    .jcf-select-drop-content {
      width: auto;
      min-width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.2) !important;


      .jcf-list {
        font-size: 14px;
        line-height: 1.43em;
        color: rgba(0, 0, 0, 0.87);
        width: auto;
        box-shadow: 5px 4px 14px rgba(0,0,0,0.12);
        min-width: 100%;

        .jcf-list-content {
          width: auto;
          min-width: 100%;
          margin: 0 3px 0 0;
          padding: 0;

          /* 1 - скроллбар */
          &::-webkit-scrollbar {
            width: 5px;
            //height: 8px;
            background-color: rgba(0, 0, 0, 0.38);
            border-radius: 4px;
          }

          /* 2 - кнопка */
          &::-webkit-scrollbar-button {
            display: none;
          }

          /* 3 - трек */
          &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.04);

          }

          /* 4 - видимая часть трека */
          &::-webkit-scrollbar-track-piece {
            background: rgba(0, 0, 0, 0.04);

          }
          /* 5 - ползунок */
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.38);
          }

          //For Mozilla FireFox
          scrollbar-color: rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.04);
          //scrollbar-width: thin;


          ul {
            width: auto;

            li {
              width: auto;

              .jcf-option {
                width: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 16px;
                color: rgba(0, 0, 0, 0.87);
                cursor: pointer;

                &-light-gray {
                  color: red;
                }

                &.jcf-disabled {
                  display: none;
                }

                &:hover {
                  background-color: rgba(0, 0, 0, 0.04);
                }

                &.jcf-selected {
                  cursor: pointer;
                  background: rgba(0, 0, 0, 0.04);

                  &:hover {
                    background: rgba(0, 0, 0, 0.04);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
//END jcf-select customization