.b-login {
    background-color: $lightGray;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-wrapper {
        width: 100%;
        max-width: 408px;
        margin: 0 auto;
        padding: 80px;
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

        .title {
            font-size: 1.3755em;
            line-height: 1.17;
            font-weight: $f-medium;
            color: $black;
            text-align: center;
        }

        .b-form {
            margin-top: 32px;
        }
    }
}
