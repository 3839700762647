/* roboto-regular - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v27-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v27-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

html {
    min-height: 100%;
}

body {
    position: relative;
    overflow-x: hidden;
    min-width: 1024px;
    min-height: 100%;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: $font-default;
    color: black;
    color: black;
    background-color: #eaeef1;
    font-weight: $f-regular;

    @media(max-width: 1024px) {
      overflow-x: auto;
    }

    &.overflow {
        position: fixed;
        overflow: hidden;
        touch-action: none;
        -ms-touch-action: none;
    }
}

a {
    transition: $all-transition;
    color: #000;

    &:hover {
        opacity: 0.8;
        text-decoration: none;
    }
}

input,
textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    color: #000;
    outline: 0 none;
    resize: none;
    overflow: hidden; //Скрывает вертикальный скролл textarea
}

/* Убираем крестик на браузерах в инпут */
input[type='text']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}
input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

/* Скрываем надпись из placeholder при клике */
input::-webkit-input-placeholder {
    color: $black;
}
input:focus::-webkit-input-placeholder {
    color: transparent;
}
/* Firefox < 19 */
input:-moz-placeholder {
    color: $black;
}
input:focus:-moz-placeholder {
    color: transparent;
}
/* Firefox > 19 */
input::-moz-placeholder {
    color: $black;
}
input:focus::-moz-placeholder {
    color: transparent;
}
/* Internet Explorer 10 */
input:-ms-input-placeholder {
    color: $black;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}

/* Если не вмещается placeholder */
input[placeholder] {
    text-overflow: ellipsis;
}

input::-moz-placeholder {
    text-overflow: ellipsis;
}
input:-moz-placeholder {
    text-overflow: ellipsis;
}
input:-ms-input-placeholder {
    text-overflow: ellipsis;
}

input[type='search'] {
    -moz-appearance: none; /* older firefox */
    -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
    appearance: none; /* rest */
}

button,
input[type='submit'] {
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none;
}
