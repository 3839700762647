.b-header {
    padding-top: 16px;

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 16px 18px;
        background-color: $white;
        border-radius: 8px;

        @media (max-width: 1250px) {
            padding: 13px 10px 18px;
        }

        .header-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .logotype-holder {
                .icon-logotype {
                    display: block;
                }
            }

            .nav-menu {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 58px;

                @media (max-width: 1250px) {
                    margin-left: 15px;
                }

                &-item {
                    position: relative;

                    & + .nav-menu-item {
                        margin-left: 32px;

                        @media (max-width: 1250px) {
                            margin-left: 10px;
                        }
                    }

                    &.drop {
                        &:hover {
                            .dropdown-menu {
                                visibility: visible;
                                height: auto;
                                opacity: 1;
                            }
                        }
                    }

                    span.nav-menu-item-link {
                      cursor: default;
                    }

                    &-link {
                         font-size: 0.7em;
                        line-height: 1.43;
                        font-weight: $f-regular;
                        color: $darkBlue;

                        @media (max-width: 1250px) {
                            font-size: 0.6em;
                        }
                    }

                    .dropdown-menu {
                        position: absolute;
                        top: calc(100%);
                        left: 0;
                        visibility: hidden;
                        height: 0;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                        padding-top: 28px;
                        z-index: 3;

                        .drop-list {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: stretch;
                            background-color: $white;
                            padding: 8px 0;
                            z-index: 3;
                            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px 9px 10px rgba(0, 0, 0, 0.03),
                                0px 5px 14px rgba(0, 0, 0, 0.03);
                            border-radius: 4px;

                            .dropdown-item {
                                width: 100%;

                                &-link {
                                    display: block;
                                    width: 100%;
                                    font-size: 0.8em;
                                    line-height: 1.5;
                                    color: rgba(0, 0, 0, 0.87);
                                    padding: 12px 19px;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }

        .header-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .search-holder {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .search-icon {
                    display: block;
                }

                .search-input {
                    appearance: none;
                    outline: none;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-bottom: 1px solid #acacac;
                    padding: 6px 10px 6px 0;
                    margin-left: 14.5px;
                    // font-size: 0.8em;
                    line-height: 1.25;
                    font-weight: $f-regular;
                    color: rgba(0, 0, 0, 0.87);
                    width: 182px;

                    @media (max-width: 1250px) {
                        font-size: 0.6em;
                        width: 142px;
                    }

                    &::-webkit-input-placeholder {
                        /* WebKit browsers */
                        color: #acacac;
                    }

                    &:-moz-placeholder {
                        /* Mozilla Firefox 4 to 18 */
                        color: #acacac;
                    }

                    &::-moz-placeholder {
                        /* Mozilla Firefox 19+ */
                        color: #acacac;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10+ */
                        color: #acacac;
                    }
                }

                .dots-icon {
                    display: block;
                    margin-left: 22px;
                }
            }

            .account-info-holder {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-left: 38px;

                @media (max-width: 1250px) {
                    margin-left: 15px;
                }

                .hold-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                    cursor: pointer;

                    .user-name {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                         font-size: 0.7em;
                        line-height: 1.43;
                        font-weight: $f-regular;
                        color: $darkBlue;

                        .user-drop-icon {
                            display: block;
                            margin-left: 14px;
                        }
                    }

                    .user-pos {
                        font-size: 0.6em;
                        line-height: 1.67;
                        font-weight: $f-regular;
                        color: #989898;
                        padding-right: 7px;
                    }
                }

                .hold-icon {
                    margin-left: 7px;

                    .user-icon {
                        display: block;
                    }
                }
            }

            .login-holder {
              margin-right: 1rem;
              font-size: 0.8em;
            }
        }
    }
}
