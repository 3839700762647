.b-reference-book {
    margin-top: 11px;

    .subheader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .breadcrumbs-holder {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .breadcrumbs-item {
                & + .breadcrumbs-item {
                    position: relative;
                    margin-left: 30px;

                    &:before {
                        position: absolute;
                        top: 50%;
                        transform: translate(50%, -50%);
                        right: calc(100%+ 12px);
                        color: black;
                        content: '/\00a0';
                        font-size: 1em;
                        line-height: 1.172;
                        font-weight: $f-medium;
                        color: rgba(0, 0, 0, 0.38);
                    }
                }
                &-link {
                    font-size: 1em;
                    line-height: 1.172;
                    font-weight: $f-medium;
                    color: rgba(0, 0, 0, 0.2);

                    &.active {
                        color: $darkBlue;
                    }
                }
            }
        }

        .controls-holder {
            display: flex;
            justify-content: flex-end;
            align-items: stretch;
            //transform: translateX(32px);

          & > :nth-child(n+2) {
            margin-left: 10px;
          }

            @media (max-width: 1885px) {
                //transform: translateX(15px);
            }

            .g-button {
              min-height: 52px;

                &.filter {
                    padding: 15.5px 33px 15.5px 19px;
                    //border-radius: 3px 0px 0px 3px;
                    border-radius: 3px;
                    text-transform: none;
                }
            }
        }
    }

    .table-holder {
        margin-top: 17px;
        height: calc(100vh - 250px);
        overflow-y: auto;

      /* 1 - скроллбар */
      &::-webkit-scrollbar {
        width: 5px;
        //height: 8px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
      }

      /* 2 - кнопка */
      &::-webkit-scrollbar-button {
        display: none;
      }

      /* 3 - трек */
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.04);

      }

      /* 4 - видимая часть трека */
      &::-webkit-scrollbar-track-piece {
        background: rgba(0, 0, 0, 0.04);

      }
      /* 5 - ползунок */
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.38);
      }

      //For Mozilla FireFox
      scrollbar-color: rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.04);
    }
}
