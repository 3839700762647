/*reset*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    font-size: 100%;
    margin: 0;
    outline: 0 none;
    padding: 0;
}
*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
html {
    box-sizing: border-box;
}
body {
    min-width: 1366px;
    position: relative;
    line-height: normal;
}
* {
    box-sizing: border-box;
}
img {
    border: 0;
    max-width: 100%;
}
a {
    outline: none;
    text-decoration: none;
}
ul {
    list-style-type: none;
}
table {
    border-collapse: collapse;
}
/*end reset*/
.clearfix:before,
.clearfix:after {
    content: ' ';
    display: table;
}
