.g-container {
    max-width: 1886px;
    min-width: 1024px;
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;

    &.header {
        max-width: 1918px;
    }
}

.g-button {
    position: relative;
    outline: 0 none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 15.5px 16px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.57;
    font-weight: $f-medium !important;
    color: $white;
    opacity: 1;
    text-align: center;
    border-radius: 4px;
    border: none;
    appearance: none;
    background-color: $darkBlue;
    white-space: nowrap;
    font-family: 'Roboto', sans-serif !important;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &.small {
        padding: 8.5px 19px;
        line-height: 1.21;
    }

    &.gray {
        background: rgba(0, 0, 0, 0.04);
        color: $darkBlue;
    }

    &.white {
        background-color: $white;
        color: $darkBlue;
    }

    &.with-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
            display: block;
            margin-right: 7px;
        }
    }

  &.guest-login {
    border: 1px solid #2e3e67;
    padding: 9.5px 14px;
    max-width: 168px;
    font-size: 14px;
    line-height: 1.21;
    margin: 0 auto;
  }
}
