.b-modal-info {
    // position: fixed;
    // top: 0;
    // left: 0;
  width: 450px;
  height: 100%;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.5);
    // display: none;

    .modal-content-wrapper {
        position: fixed;
        right: 0;
        top: 0;
        width: 536px;
        background-color: $white;
        height: 100%;
        overflow-y: auto;

      /* 1 - скроллбар */
      &::-webkit-scrollbar {
        width: 5px;
        //height: 8px;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 4px;
      }

      /* 2 - кнопка */
      &::-webkit-scrollbar-button {
        display: none;
      }

      /* 3 - трек */
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.04);

      }

      /* 4 - видимая часть трека */
      &::-webkit-scrollbar-track-piece {
        background: rgba(0, 0, 0, 0.04);

      }
      /* 5 - ползунок */
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.38);
      }

      //For Mozilla FireFox
      scrollbar-color: rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.04);

        @media (max-width: 1365px) {
            width: 450px;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 24px 28px;
            background-color: #ededed;

            &.with-small-icon {
                align-items: center;
            }

            .header-left {
                .title {
                    font-size: 1.2em;
                    line-height: 1.41;
                    font-weight: $f-medium;
                    color: $darkBlue;
                }

                .map-link {
                    font-size: 0.7em;
                    line-height: 1.43;
                    font-weight: $f-regular;
                    color: #343434;
                    text-decoration: underline;
                    margin-top: 2px;
                }
            }

            .header-right {
                .edit-link {
                    display: block;
                    margin-top: 4px;

                    .icon {
                        display: block;
                    }
                }
            }
        }

        .modal-body {
            padding: 40px 28px;

            &.without-tabs {
                padding: 24px 28px;
            }

            .tabs-header {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;

                &-link {
                    position: relative;
                    font-size: 0.8em;
                    line-height: 1.4;
                    font-weight: $f-medium;
                    color: rgba(0, 0, 0, 0.54);
                    padding: 0 16px 0 16px;
                    transition: all 0.3s ease-in-out;

                    &:before {
                        position: absolute;
                        display: block;
                        content: '';
                        right: 0;
                        top: calc(100% + 11px);
                        width: 100%;
                        height: 1px;
                        background-color: rgba(0, 0, 0, 0.54);
                        transition: all 0.3s ease-in-out;
                    }

                    &.active {
                        color: $darkBlue;

                        &:before {
                            height: 3px;
                            top: calc(100% + 10px);
                            background-color: $darkBlue;
                        }
                    }
                }
            }

            .tabs-content {
                &-item {
                    margin-top: 41px;

                    &.faded {
                        animation-name: faded;
                        animation-duration: 0.5s;
                    }
                }
            }

            .map-holder {
                width: 100%;
                border-radius: 12px;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.19);
                margin-bottom: 25px;

                img {
                    width: 100%;
                    display: block;
                    border-radius: 12px;
                }
            }

            .info-holder {
                & + .info-holder {
                    margin-top: 24px;
                }

                &.with-border {
                    padding-bottom: 24px;
                    border-bottom: 1px solid #d5d5d5;
                }

                .info-title {
                    font-size: 0.9em;
                    line-height: 1.17;
                    font-weight: $f-medium;
                    color: $darkBlue;
                }

                .info-group {
                    margin-top: 16px;
                    max-width: 345px;

                    &.inline {
                        max-width: none;

                      .info-group-title {
                        display: inline;
                      }

                        .info-group-value {
                          display: inline;
                          margin-left: 10px;
                        }
                    }

                    &-title {
                        font-size: 0.6em;
                        line-height: 1.67;
                        font-weight: $f-regular;
                        color: #8a8a8a;
                    }

                    &-value {
                        font-size: 0.6em;
                        line-height: 1.67;
                        font-weight: $f-regular;
                        color: #151515;
                    }
                }

                .operations-group {
                    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
                    border-radius: 0 0 12px 12px;
                    margin-top: 24px;
                    margin: 24px 4px 0;

                    & + .operations-group {
                        margin: 16px 4px 0;
                    }

                    &-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 16px 20px 16px 16px;
                        background-color: #ededed;
                        border-radius: 0px 0px 8px 8px;
                        font-size: 0.8em;
                        font-weight: 400;
                        line-height: 1.25;
                    }

                    &-body {
                        display: flex;
                        justify-content: flex-start;
                        align-items: stretch;
                        flex-wrap: wrap;
                        padding: 8px 16px 14px;
                        max-width: 460px;

                        .operations-item {
                            width: calc((100% - 33px) / 2);
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}

@keyframes faded {
    from {
        opacity: 0.1;
    }

    to {
        opacity: 1;
    }
}
