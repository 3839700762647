.b-form {
  width: 100%;

  .row-title {
    font-size: 0.9em;
    line-height: 1.17;
    font-weight: $f-medium;
    color: $darkBlue;
    margin-bottom: 24px;
    padding-top: 24px;

    &.with-top-border {
      padding-top: 24px;
      border-top: 1px solid #D5D5D5;
      margin-top: 24px;
    }
  }

  .add-button-holder {
    margin-top: 30px;
    width: auto;

    .g-button {
      display: block;
      max-width: 186px;

      &.with-border {
        padding: 8.5px 9px;
      }

      &.white {
        background-color: white;
        color: #1B4159;
        border: 1px solid #1B4159;
      }
    }
  }

  .form-row {
    width: 100%;

    &+.form-row {
      margin-top: 16px;
    }

    &.remember-checkbox {
      margin-top: 11px;
    }

    &.checkbox, &.radio {
      &+.form-row {
        margin-top: 23px;
      }
    }

    &.button-holder {
      margin-top: 35px;
    }

    &.two-column {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      .form-element {
        width: calc((100% - 16px)/2);

        &+.form-element {
          margin-left: 16px;
        }
      }
    }

    .form-element {
      width: 100%;

      .form-label {
        display: block;
        font-size: 0.7em;
        line-height: 1.17;
        font-weight: $f-regular;
        color: rgba(0, 0, 0, 0.38);
        margin-bottom: 8px;
      }

      .custom-checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &+.checkbox-label {
          display: inline-flex;
          align-items: center;
          user-select: none;
          cursor: pointer;
          font-size: 0.7em;
          line-height: 1.21;
          font-weight: $f-regular;
          color: rgba(0, 0, 0, 0.87);
          margin-bottom: 0;

          &:hover {
            opacity: 0.8;
          }

          &:before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            flex-grow: 0;
            border: 2px solid rgba(0, 0, 0, 0.74);;
            border-radius: 2px;
            margin-right: 11px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
          }
        }

        &:checked {
          &+.checkbox-label {
            &:before {
              border-color: #2E3E67;
              background: url("../../img/icon_checkbox_checked.svg") no-repeat center;
            }
          }
        }
      }

      .custom-radio {
        position: absolute;
        left: -9999px;

        &+.form-label {
          max-width: none;
          position: relative;
          padding-left: 30px;
          cursor: pointer;
          display: inline-block;
          white-space: nowrap;
          font-size: 0.7em;
          line-height: 1.21;
          font-weight: $f-regular;
          color: rgba(0, 0, 0, 0.87);
          margin-bottom: 0;


          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -2px;
            //transform: translateY(-50%);
            width: 20px;
            height: 20px;
            border: 2px solid rgba(0, 0, 0, 0.87);
            border-radius: 100%;
            background: #fff;
          }

          &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: $darkBlue;
            position: absolute;
            top: 3px;
            left: 5px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
        }


        &:checked {
          &+.form-label {

            &:before {
              border-color: $darkBlue;
            }

            &:after {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        }

        &:not(:checked) {

          &+.form-label {

            &:after {
              opacity: 0;
              -webkit-transform: scale(0);
              transform: scale(0);
            }
          }
        }
      }

      .custom-switch-toggle {
        height: 0;
        width: 0;
        visibility: hidden;

        &+.form-label {
          cursor: pointer;
          //text-indent: -9999px;
          width: 36px;
          height: 15px;
          background-color: rgba(80, 80, 80, 0.7);
          display: inline-block;
          border-radius: 15px;
          position: relative;
          margin-bottom: 0;

          span {
            white-space: nowrap;
            padding-left: 50px;
            font-weight: 400;
            color: rgba(0,0,0,.87);
          }

          &:active {
            &:after {
              width: 50px;
            }
          }

          &:after {
            content: '';
            position: absolute;
            top: -3px;
            left: -6px;
            width: 21px;
            height: 21px;
            background: #fff;
            border-radius: 50%;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
            transition: 0.3s;
          }
        }

        &:checked {
          &+.form-label {
            background: #2E3E67;

            &:after {
              left: calc(100% + 6px);
              transform: translateX(-100%);
            }

          }
        }
      }

      .form-input {
        width: 100%;
        padding: 9px 15px;
        font-size: 0.7em;
        line-height: 1.17;
        font-weight: $f-regular;
        color: rgba(0, 0, 0, 0.87);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 2px;

        &::-webkit-input-placeholder { /* WebKit browsers */
          color: rgba(0, 0, 0, 0.2);
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: rgba(0, 0, 0, 0.2);
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: rgba(0, 0, 0, 0.2);
        }
        &:-ms-input-placeholder { /* Internet Explorer 10+ */
          color: rgba(0, 0, 0, 0.2);
        }

        &.error-show {
          border-color: $lightRed;
        }

        &:disabled {
          background: rgba(0, 0, 0, 0.04);
        }

        &.datePicker {
          max-width: 265px;
          position: relative;
          background: url('../../img/icon_calendar.svg') no-repeat top 50% right 18px;

          &.full-width {
            max-width: none;
          }
        }
      }

      .error-message {
        display: none;
        font-size: 0.55em;
        line-height: 1.45;
        font-weight: $f-regular;
        color: $lightRed;
        margin-top: 4px;

        &.error-show {
          display: block;
        }
      }

      .password-form-wrapper {
        position: relative;

        #password {
          &:valid {
            &+.password-control {
              background: url('../../img/icon_show_password_active.svg') no-repeat center;

              &.view {
                background: url('../../img/icon_hide_password_active.svg') no-repeat center;
              }
            }
          }
        }

        .password-control {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 17px;
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('../../img/icon_show_password_disabled.svg') no-repeat center;

          &.view {
            background: url('../../img/icon_hide_password_disabled.svg') no-repeat center;
          }
        }
      }



      &.button-login {
        display: flex;
        justify-content: center;

        .g-button {
          padding: 9.5px 60.5px;
          line-height: 1.21;
        }
      }
    }
  }
}
