.b-pagination {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .pagination-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        min-width: 100%;
        background-color: $white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.1);

        .paginate-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            height: 34px;
            background-color: $white;
            border-radius: 4px;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px 9px 10px rgba(0, 0, 0, 0.03),
                0px 5px 14px rgba(0, 0, 0, 0.03);
            font-size: 0.8em;
            line-height: 1.37;
            color: rgba(0, 0, 0, 0.87);

            & + .paginate-button {
                margin-left: 10px;
            }

            &.active {
                background-color: $darkBlue;
                color: $white;
                pointer-events: none;
            }

            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }

            &:hover {
                border: 1px solid #2e3e67;
            }
        }
    }
}
