.b-map {
    margin-top: 11px;
    height: calc(100vh - 100px);

    .subheader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .breadcrumbs-holder {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .breadcrumbs-item {
                & + .breadcrumbs-item {
                    position: relative;
                    margin-left: 30px;

                    &:before {
                        position: absolute;
                        top: 50%;
                        transform: translate(50%, -50%);
                        right: calc(100%+ 12px);
                        color: black;
                        content: '/\00a0';
                        font-size: 1em;
                        line-height: 1.172;
                        font-weight: $f-medium;
                        color: rgba(0, 0, 0, 0.38);
                    }
                }
                &-link {
                    font-size: 1em;
                    line-height: 1.172;
                    font-weight: $f-medium;
                    color: rgba(0, 0, 0, 0.2);

                    &.active {
                        color: $darkBlue;
                    }
                }
            }
        }
    }

    .map-wrapper {
        margin-top: 10px;

        img {
            display: block;
            height: 100%;
            width: auto;
            overflow-x: hidden;
        }
    }
}
