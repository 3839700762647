/* body {
    background-color: lightgray;
} */
/* pagination */
.pagination {
    padding: 0;
    display: inline-block;
    max-width: 285px;
    margin: 0;
    font-size: 12px;
}
.pagination li {
    display: inline-block;
    padding: 0px 5px;
    height: 25px;
    width: 25px;
    line-height: 25px;
    background: none;
    margin: 0;
    cursor: pointer;
    text-align: center;
}
.pagination li a {
    outline: none;
}
.pagination li .MuiIconButton-root {
    outline: none;
}
.pagination li.active {
    background: #2e3e67;
}

.pagination li.active a {
    color: white;
}

.pagination .previous,
.pagination .next,
.pagination .break-me {
    background: none;
    margin: 0;
    padding: 0;
}
.pagination .previous,
.pagination .next {
    margin: 0;
    padding-top: 5px;
    height: 100%;
    vertical-align: middle;
}
.pagination li.disabled {
    opacity: 0.5;
    cursor: default;
}

.MuiTable-root .MuiTablePagination-toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
}
.MuiTable-root .MuiTablePagination-spacer {
    display: none;
}
/* .MuiTable-root .MuiTablePagination-input.MuiTablePagination-selectRoot {
    display: none;
} */
.MuiTable-root .MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit {
    display: none;
}

.hold-pagination {
    z-index: 2;
    width: 100%;
    overflow-x: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: white;
    padding: 10px;
    box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.1);
}

.hold-pagination .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hold-pagination .pagination li {
    padding: 0;
    min-width: 34px;
    height: 34px;
    box-shadow: 0 6px 7px rgb(0 0 0 / 10%);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hold-pagination .pagination li a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 5px;
    width: 100%;
    height: 100%;
}

.hold-pagination .pagination li + li {
    margin-left: 10px;
}

.MuiDataGrid-row {
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
}

.MuiDataGrid-root div.MuiDataGrid-footerContainer {
    display: none;
}

.MuiDataGrid-renderingZone {
    max-height: 100% !important;
}

.dropdown-wrapper {
    background-color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px 9px 10px rgba(0, 0, 0, 0.03), 0px 5px 14px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
}

.MuiPaper-root::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
}

.MuiPaper-root::-webkit-scrollbar-button {
    display: none;
}

.MuiPaper-root::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.04);
}

.MuiPaper-root::-webkit-scrollbar-track-piece {
    background: rgba(0, 0, 0, 0.04);
}

.MuiPaper-root::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.38);
}

.MuiPaper-root {
    scrollbar-color: rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.04);
}

.select-placeholder {
    color: #000 ;
    opacity: 0.3;
}

.MuiListItem-root .select-placeholder {
    color: inherit;
    opacity: 1;
}
